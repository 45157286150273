@import "../Master";

.DDImage {

    overflow-y: hidden;
    width: 100%;

    @media (min-width: $desktop-resolution) {
        overflow: hidden;
    }

    .Full {
        width: 100%;
        height: auto;
        overflow-y: hidden;
    }

    .Fit-Contain {
        object-fit: contain;
    }

    .Fit-Cover {
        object-fit: cover;
    }

    .Fit-None {
        object-fit: none;
    }

    .Image {
        @media (min-width: $desktop-resolution) {
            // width: 100%;
        }
        object-fit: cover;
    }
}