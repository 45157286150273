@import "Master";

.LD-Head {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: $space-XL;
    
    .Text-1 {
        @extend .Black-1;
        @extend .Body-1;
    }
    
    .Text-2 {
        @extend .Black-2;
        @extend .Body-2;
    }
}