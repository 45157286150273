@import "../Master";

.Sidebar {
    width: 206px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $space-S;

    z-index: 3;
    left: 8%;

    padding-top: $space-XXL;

    position: sticky;
    top: 50px;

    // For 1366px
    @media (max-width: 1366px) {
        display: none;
    }

    .Extra {
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        margin-right: $space-XL;

        width: 206px;
    }

    .Page-Tracker {
        display: flex;
        width: 200px;
        padding: 0;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: $space-S;

        .Item {
            @extend .Black-2;
            @extend .Body-3;

            display: flex;
            height: 32px;
            align-items: center;
            gap: 8px;
            align-self: stretch;

            cursor: pointer;
        }

        .Active {
            @extend .Green-1;

            font-weight: 700;
        }
    }

    .Slider {
        width: 2px;
        height: 100%;
        flex-shrink: 0;

        border-radius: 4px;
        background-color: $white-3;

        .Active {
            height: 100px;

            border-radius: 4px;
            background-color: $black-3;

            // Add animation
            transition: height 0.5s;
        }
    }

    .Progress {
        p {
            @extend .Black-2;
            @extend .Body-3;
            text-align: right;
        }
        margin-right: $space-XL;
        width: 206px;
    }
}