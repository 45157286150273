@import 'Master';

.Other-Project-Box {
    display: flex;
    flex-direction: column;
    gap: $space-L;

    // Desktop
    @media only screen and (min-width: $desktop-resolution) {
        flex-direction: row;
    }
}

.Coming-Soon-Card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 $space-XL;
    background-color: $white-1;
    gap: $space-S;
    opacity: 0;
    z-index: 1;
    position: relative;
    border-radius: $corner-radius;
    height: 400px;

    // Opacity transition
    transition: opacity 0.5s ease-in-out;
}

.Coming-Soon-Card:hover {
    opacity: 1;
}

.Project-Card {
    display: flex;
    flex-direction: column;
    height: 400px;
    gap: $space-S;

    > img.Project-Image {
        border-radius: $corner-radius;
        width: 300px;
        height: 206px;
        object-fit: cover;
    }
}
