@import 'Master';

.Essay-View {
    // Set opacity to 0
    opacity: 0;
    // Animation on opacity
    transition: opacity 0.5s ease-in-out;

    width: 100%;

    .Content-Essay {
        @extend .Flex;
        @extend .F-Col;
        @extend .Gap-L;
        
        p {
            @extend .Body-2;
            @extend .Black-1;
        }

        > h2 {
            @extend .Title-2;
            @extend .Black-1;
        }

        > ul {
            @extend .Padding-Left-M;
            > li {
                @extend .Body-2;
                @extend .Black-1;
            }
        }

        > ol {
            @extend .Padding-Left-M;

            > li {
                @extend .Body-2;
                @extend .Black-1;
            }
        }
    }

    .Footer-Note {
        margin-top: $space-L;
        padding-top: $space-S;
        border-top: 1px solid $black-3;

        ol {
            list-style-type: none;
            counter-reset: custom-counter;
        
            padding-left: 0;
        }

        ol > li {
            @extend .Body-2;
            @extend .Black-2;
        }

        ol > li:before {
            content: "[" counter(custom-counter) "] ";
            counter-increment: custom-counter;
        }
    }
}