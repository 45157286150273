.Anima {
    transition: background-color .35s linear, transform 0.25s ease-out;
}

.DDBlacker {
    transition: opacity 0.5s ease, background-color 0.35s linear;
    background-color: #000;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 6;
    // By default, opacity is set to 1
    opacity: 1;
}

.DDWhiter {
    @extend .DDBlacker;
    background-color: $white-1;
    z-index: 6;
    // By default, opacity is set to 1
    opacity: 1;
}