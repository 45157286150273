@import "Master";

.LD-About {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: $space-M;

    .Title {
        @extend .Title-3;
        @extend .Green-1;
    }

    .Text1 {
        @extend .Body-2;
        @extend .Black-1;

        // br {
        //     display: block;
        //     content: ' ';
        //     margin: 18px 0 0 0;
        // }
    }

    .Text2 {
        @extend .Body-2;
        @extend .Black-2;

        // br {
        //     display: block;
        //     content: ' ';
        //     margin: 18px 0 0 0;
        // }
    }

    .Footer {
        display: flex;
        align-items: flex-start;
        gap: $space-XL;

        .Link-Section {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: $space-S;

            .Title {
                @extend .Body-3;
                @extend .Black-2;
            }

            .Link {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                gap: $space-S;

                // For desktop
                @media only screen and (min-width: $desktop-resolution) {
                    flex-direction: row;
                    gap: $space-M;
                }

                a {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 4px;

                    text-decoration: none;

                    // Link text
                    p {
                        @extend .Body-3;
                        @extend .Black-1;
                    }

                    // Link icon
                    img {
                        width: 16px;
                        height: 16px;
                    }
                }

                a:hover {
                    p {
                        @extend .FW-600;
                    }
                    img {
                        content: url('../images/arrow_active.svg');
                    }
                }
            }
        }

        .Social-Section {
            @extend .Link-Section;
        }

        .Other-Section {
            @extend .Link-Section;
        }
    }
}