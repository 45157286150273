@import 'Master';

.Essay-Image {
    @extend .Flex;
    @extend .F-Col;
    @extend .Gap-S;

    > .Image-Section {
        > img {
            width: 100%;
            object-fit: cover;
        }
    }

    > p.Image-Desc {
        @extend .Body-3;
        @extend .Black-2;
    }
}