@import 'Master';

.LD-Essay {
    display: flex;
    flex-direction: column;
    gap: $space-M;

    width: 100%;

    .Title {
        @extend .Title-3;
        @extend .Green-1;
    }

    .Blog {
        // Set opacity to 0 by default
        // It will be animated later
        opacity: 0;
        // Set transition
        transition: opacity 0.5s ease-in-out;

        $blog-border-radius: 20px;

        > *:first-child {
            border-radius: $blog-border-radius $blog-border-radius 0px 0px;
        }

        > *:last-child {
            border-radius: 0px 0px $blog-border-radius $blog-border-radius;
        }

        // All child except last child
        > *:not(:last-child) {
            border-bottom: 1px solid $black-4;
        }
        
        .Blog-Item {
            display: flex;
            flex-direction: row;
            gap: $space-M;
            padding: $space-M;
            
            background-color: $white-3;
            
            cursor: pointer;
            
            .Left {
                display: flex;
                flex-direction: column;
                width: 100%;

                .Blog-Title {
                    @extend .Body-2;
                    @extend .FW-600;
                    @extend .Black-1;
                }
    
                .Blog-Content-Truncated {                    
                    > p {
                        @extend .Body-2;
                        @extend .Black-2;

                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }

            .Right {
                img {
                    content: url('../images/essay-arrow.svg');
                }
            }

        }

        .Blog-Item:hover {
            background-color: $white-1;

            > .Left {
                > .Blog-Title {
                    @extend .Black-2;
                }
            }

            .Right {
                img {
                    content: url('../images/essay-arrow-active.svg');
                }
            }
        }
    }
}