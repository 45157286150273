@import 'Master';

.Nav-Bar-Container {
    top: 0;
    z-index: 5;
    display: flex;
    justify-content: center;
    width: 100%;

    > .Nav-Bar {
        display: flex;
        padding: $space-XL $space-L $space-L $space-L;
        justify-content: space-between;
        width: 100%;
        
        // For desktop
        @media only screen and (min-width: $desktop-resolution) {
            padding: $space-XL 0px $space-L 0px;
            width: 640px;
        }
    
        > img.Nav-Logo {
            cursor: pointer;
        }
        
        > .Links {
            display: flex;
            gap: $space-L;
    
            > .Link-Item {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 1px;
                width: 48px;
                height: 32px;
                cursor: pointer;
    
                > .Link-Text {
                    @extend .Body-3;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 20px;
                    letter-spacing: 2%;
                    color: $black-2;
                }
    
                > img.Eclipse {
                    width: 5px;
                    height: 5px;
    
                    opacity: 0;
                }
            }
    
            > .Link-Item.Active {
                > .Link-Text {
                    color: $black-1;
                }
    
                > img.Eclipse {
                    opacity: 1;
                }
            }
        }
    }
    
}

.Nv-Sticky {
    position: sticky;
}

.Nv-Bg-White-1 {
    background: linear-gradient(180deg, $white-1 0%, $white-1 34.19%, rgba(241, 243, 243, 0.98) 69.69%, rgba(241, 243, 243, 0) 100%);
}

.Nv-Bg-White-2 {
    background: linear-gradient(180deg, $white-2 0%, $white-2 34.19%, rgba(241, 243, 243, 0.98) 69.69%, rgba(241, 243, 243, 0) 100%);
}

.Nv-Bg-White-3 {
    background: linear-gradient(180deg, $white-3 0%, $white-3 34.19%, rgba(241, 243, 243, 0.98) 69.69%, rgba(241, 243, 243, 0) 100%);
}

.Nv-Bg-Black-1 {
    background: linear-gradient(180deg, $black-1 0%, $black-1 34.19%, rgba(241, 243, 243, 0.98) 69.69%, rgba(241, 243, 243, 0) 100%);
}

.Nv-Bg-Black-3 {
    background: linear-gradient(180deg, $black-3 0%, $black-3 34.19%, rgba(241, 243, 243, 0.98) 69.69%, rgba(241, 243, 243, 0) 100%);
}

.Nv-Bg-Black-4 {
    background: linear-gradient(180deg, $black-4 0%, $black-4 34.19%, rgba(241, 243, 243, 0.98) 69.69%, rgba(241, 243, 243, 0) 100%);
}

.Nv-Bg-Green-1 {
    background: linear-gradient(180deg, $green-1 0%, $green-1 34.19%, rgba(241, 243, 243, 0.98) 69.69%, rgba(241, 243, 243, 0) 100%);
}